import Link from '@/atoms/Link/Link'
import Image from 'next/image'

/* assets */
import { ReactComponent as ArrowRight } from 'public/assets/icons/chevron-right.svg'

interface Props {
  title: string
  lists: StrapiCaseStudyCardComponent[]
}

const CaseStudyGallery = ({ title, lists }: Props) => {
  return (
    <section>
      <div className="wrapper | container | py-10 md:py-18">
        {title ? (
          <h2 className="font-heading font-medium text-3xl md:text-5xl | mb-12 lg:mb-14 md:pr-44">
            {title}
          </h2>
        ) : null}
        <div className="gallery | grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:row-[1/3]">
            {lists[0] ? (
              <CaseStudyCard
                name={lists[0].customerName}
                designation={lists[0].customerDesignation}
                cta={lists[0].caseStudyCta}
                brandLogo={lists[0].companyLogo.data?.attributes}
                bannerImg={lists[0].bannerImage.data?.attributes}
                avatar={lists[0].avatar.data?.attributes}
                testimonialContent={lists[0].testimonialContent}
              />
            ) : null}
          </div>
          <div className="md:row-[1/2]">
            {lists[1] ? (
              <CaseStudyCard
                variant="small"
                name={lists[1].customerName}
                designation={lists[1].customerDesignation}
                cta={lists[1].caseStudyCta}
                brandLogo={lists[1].companyLogo.data?.attributes}
                avatar={lists[1].avatar.data?.attributes}
                testimonialContent={lists[1].testimonialContent}
              />
            ) : null}
          </div>
          <div className="md:row-[2/3]">
            {lists[2] ? (
              <CaseStudyCard
                variant="small"
                name={lists[2].customerName}
                designation={lists[2].customerDesignation}
                cta={lists[2].caseStudyCta}
                brandLogo={lists[2].companyLogo.data?.attributes}
                avatar={lists[2].avatar.data?.attributes}
                testimonialContent={lists[2].testimonialContent}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyGallery

type CardProps = {
  variant?: 'small' | 'large'
  name: string
  designation: string
  cta: StrapiLinkSharedComponent
  bannerImg?: StrapiImageFile
  brandLogo: StrapiImageFile
  avatar: StrapiImageFile
  testimonialContent: string
}

const CaseStudyCard: React.FC<CardProps> = ({
  variant = 'large',
  name,
  designation,
  cta,
  brandLogo,
  bannerImg,
  avatar,
  testimonialContent
}) => {
  return variant === 'large' ? (
    <div className="grid grid-rows-[auto_1fr] | rounded-2xl | overflow-hidden | h-full">
      {bannerImg ? (
        <div className="clientAvatar | relative | w-full">
          <Image
            src={bannerImg.url}
            height={bannerImg.height}
            width={bannerImg.width}
            alt={bannerImg.alternativeText ?? ''}
          />
        </div>
      ) : null}
      <div className="bg-[#B3F4FF] | px-8 py-8 | flex flex-col">
        {brandLogo ? (
          <Image
            src={brandLogo.url}
            height={brandLogo.height}
            width={brandLogo.width}
            alt={brandLogo.alternativeText ?? ''}
            className="w-32 mb-6"
          />
        ) : null}
        <p className="font-heading text-base md:text-2xl text-gray-90 | mb-8">
          {testimonialContent}
        </p>
        <div className="client_details | flex gap-6 items-center | mb-9">
          <div className="avatar | rounded-full | w-16 h-16 | relative overflow-hidden | shrink-0">
            {avatar ? (
              <Image
                src={avatar.url}
                width={avatar.width}
                height={avatar.height}
                alt={avatar.alternativeText ?? 'client'}
              />
            ) : null}
          </div>
          <div className="font-body">
            <div className="text-small md:text-lg font-medium">{name}</div>
            <div className="text-small md:text-lg">{designation}</div>
          </div>
        </div>
        <div className="flex flex-grow items-end py-6 md:py-0">
          <Link
            href={cta.href}
            target={cta.target}
            className="inline-flex items-center justify-center | bg-blue-50 | rounded-md | font-medium font-body text-lg text-white | py-2 px-4 | w-fit"
          >
            {cta.title}
            <ArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-[#1C1658] rounded-2xl | relative | text-white">
      <div className="relative px-10 py-7">
        <div className="logo">
          {brandLogo ? (
            <Image
              src={brandLogo.url}
              height={brandLogo.height}
              width={brandLogo.width}
              alt={brandLogo.alternativeText ?? ''}
              className="object-contain | w-fit pb-3"
            />
          ) : null}
        </div>
        <div className="content | font-heading font-normal text-2xl | pb-8">
          {testimonialContent}
        </div>
        <div className="client_details | flex gap-6 items-center | pb-9">
          <div className="avatar | rounded-full | w-16 h-16 | overflow-hidden | shrink-0">
            {avatar ? (
              <Image
                src={avatar.url}
                width={avatar.width}
                height={avatar.height}
                alt={avatar.alternativeText ?? 'client'}
              />
            ) : null}
          </div>
          <div className="font-body">
            <div className="name | text-lg font-medium">{name}</div>
            <div className="designation | text-lg">{designation}</div>
          </div>
        </div>
        <div className="flex-grow items-end">
          <Link
            href={cta.href}
            target={cta.target}
            className="inline-flex items-center justify-center | bg-blue-50 | rounded-md | font-medium font-body text-lg text-white | py-2 px-4 | w-fit"
          >
            {cta.title}
            <ArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  )
}
