import { useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as CubeScanIcon } from 'public/assets/homePage/icons/3d-cube-scan.svg'
import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'

/* styling */
import styles from './_.module.css'

type TabId = 'strategise' | 'create' | 'manage' | 'distribute' | 'analyse'

function PlatformFeatures(): JSX.Element {
  const [activeTab, setActiveTab] = useState<TabId>('strategise')

  return (
    <section id="platform-features" className="container | py-10 md:py-18">
      <div className="text-purple-50 | flex items-center gap-2 | mb-6 lg:mb-5">
        <CubeScanIcon />
        <p className="text-sm lg:text-xl font-heading font-semibold tracking-widest uppercase">
          Pepper Content Platform
        </p>
      </div>
      <h2 className="font-medium font-heading text-3xl lg:text-6xl tracking-tight | mb-16 lg:mb-28">
        The Content Marketing Platform for Business Teams
      </h2>

      <div className="flex items-center justify-between whitespace-nowrap | gap-7 | overflow-auto | mb-10">
        {Object.values(tabs).map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(tab.id as TabId)}
            className={clsx(
              'flex items-center gap-4 shrink-0 | py-4 px-2',
              styles.hoverBorder,
              activeTab === tab.id ? styles.activeBorder : ''
            )}
          >
            <img
              src={tab.icon}
              alt=""
              width={35}
              height={35}
              className="w-9 h-9"
            />
            <span className="font-heading font-semibold text-xl lg:text-2xl text-purple-50">
              {tab.title}
            </span>
          </button>
        ))}
      </div>

      <div className="bg-purple-10 | rounded-lg | py-10 px-5 lg:px-12 lg:py-16">
        <div
          key={tabs[activeTab].id}
          className={clsx(
            'grid grid-cols-1 lg:grid-cols-2 gap-8 items-center',
            styles.animate
          )}
        >
          <div>
            <h3 className="text-3xl text-blue-web-90 font-heading font-medium | mb-9">
              {tabs[activeTab].heading}
            </h3>
            <ul className="list-disc list-inside | text-lg text-blue-web-90 | pl-4 space-y-3 mb-10">
              {tabs[activeTab].list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <Link
              href={tabs[activeTab].ctaLink}
              className="text-blue-web-50 font-medium | inline-flex items-baseline gap-2 | border-b-2"
            >
              Explore More <ArrowLeft />
            </Link>
          </div>

          <Image src={tabs[activeTab].image} alt="" width={1159} height={892} />
        </div>
      </div>
    </section>
  )
}

const tabs = {
  strategise: {
    id: 'strategise',
    title: 'Strategise',
    icon: '/assets/homePage/icons/strategise.svg',
    image: '/assets/homePage/images/strategise.png',
    heading: 'Power your complete content lifecycle',
    list: [
      'Data-driven strategy to create impactful content',
      'Efficient operations to scale content creation',
      'Actionable insights to improve performance'
    ],
    ctaLink: '/product/content-marketing-platform/'
  },
  create: {
    id: 'create',
    title: 'Create',
    icon: '/assets/homePage/icons/edit.svg',
    image: '/assets/homePage/images/create.png',
    heading: 'Create high-quality content, at scale',
    list: [
      'Search-optimized content, by default',
      'AI-powered writing assistance',
      'Automated content audits'
    ],
    ctaLink: '/features/optimised-content-creation/'
  },
  manage: {
    id: 'manage',
    title: 'Manage',
    icon: '/assets/homePage/icons/document-sketch.svg',
    image: '/assets/homePage/images/manage.png',
    heading: 'Workflows that streamline your content marketing',
    list: [
      'Quick feedback and approval process',
      'Single-dashboard visibility of the content pipeline',
      'Efficient collaboration between teams'
    ],
    ctaLink: '/product/content-os/'
  },
  distribute: {
    id: 'distribute',
    title: 'Distribute',
    icon: '/assets/homePage/icons/maximize.svg',
    image: '/assets/homePage/images/distribute.png',
    heading: 'Publish your content in a few clicks',
    list: [
      'Integration with your preferred CMS',
      'Quick distribution to reach your audience',
      'Hassle-free publishing with no additional formatting'
    ],
    ctaLink: '/features/publishing-platform-integration/'
  },
  analyse: {
    id: 'analyse',
    title: 'Analyse',
    icon: '/assets/homePage/icons/fatrows.svg',
    image: '/assets/homePage/images/analyse.png',
    heading: 'Measure your content ROI',
    list: [
      'Content audit for the complete website',
      'Keyword position tracking without spreadsheets',
      'Actionable insights to improve existing content'
    ],
    ctaLink: '/features/measure-content-roi/'
  }
}

export default PlatformFeatures
