import Image from 'next/image'

interface Props {
  title: string
  images: {
    id: string | number
    image: StrapiImageFile
  }[]
}

function Awards({ title, images }: Props): JSX.Element {
  return (
    <section className="bg-blue-web-90 text-white">
      <div className="container flex flex-col gap-12 items-center | py-10 md:py-18">
        <h2
          className="font-heading font-semibold md:font-medium text-2xl md:text-5xl text-center"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-7">
          {images.map(val => {
            return (
              <div key={val.id}>
                <Image
                  src={val.image.url}
                  width={val.image.width}
                  height={val.image.height}
                  alt={val.image.alternativeText ?? ''}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Awards
