import clsx from 'clsx'
import Image from 'next/image'

import Link from '@/atoms/Link/Link'

import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'

/* styling */
import styles from './_.module.css'
function FooterExtended() {
  return (
    <section className="bg-blue-90">
      <div className="container | font-heading text-white | grid grid-cols-1 md:grid-cols-12 | relative | py-10 md:py-18">
        <Image
          src="/assets/homePage/images/rocket.png"
          alt="Quotes"
          width={489}
          height={157}
          className="md:hidden mx-auto lg:mx-0 mb-18"
        />
        <div
          className={clsx(
            styles.text,
            'md:col-span-7 | text-3xl md:text-6xl font-medium relative order-2 | mb-24'
          )}
        >
          <div className="mx-12 mb-14 md:mb-0">
            <span className="text-cyan-30">Ready to power</span> your content
            marketing lifecycle?
          </div>
        </div>
        <div className="md:col-start-8 md:col-span-5 order-3 | lg:-ml-32">
          <Image
            src="/assets/homePage/images/rocket.png"
            alt="Quotes"
            width={489}
            height={157}
            className="hidden md:block | mt-12 mb-18"
          />
          <div className="font-body font-medium flex flex-col sm:flex-row justify-center | gap-6">
            <Link
              href="https://business.peppercontent.io/signup"
              className="new-btn-tertiary"
            >
              Sign Up
              <ArrowLeft className="ml-2 | text-purple-60" />
            </Link>
            <Link href="/book-a-demo/" className="new-btn-secondary">
              Book A Demo
              <ArrowLeft className="ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterExtended
