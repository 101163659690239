import { useState, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'
import YoutubePlayer from '@/atoms/YoutubePlayer/YoutubePlayer'

/* assets */
import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'

/* styling */
import styles from './_.module.css'

interface Props {
  class?: string
  primaryHeadline: string
  subText: string
  primaryCta: StrapiLinkSharedComponent
  secondaryCta?: StrapiLinkSharedComponent | null
  footNote?: string | null
  image?: StrapiImageFile
  layout?: 'center' | 'left'
  videoLink?: string | null
  mode?: 'light' | 'dark'
}

function PageHero({
  layout = 'center',
  mode = 'dark',
  ...props
}: Props): JSX.Element {
  return (
    <section
      className={clsx(
        props.class,
        mode === 'dark'
          ? 'bg-blue-web-90 text-white'
          : 'bg-white text-blue-web-90'
      )}
    >
      {layout === 'center' ? (
        <CenterLayout mode={mode} {...props} />
      ) : (
        <LeftLayout mode={mode} {...props} />
      )}
    </section>
  )
}

function CenterLayout({
  primaryHeadline,
  subText,
  footNote,
  primaryCta,
  secondaryCta,
  videoLink,
  image,
  mode
}: Props): JSX.Element {
  const stringWithoutBracketContents = primaryHeadline.replace(/\[.*?\]/g, '')
  const startIndex = primaryHeadline.indexOf('[') + 1
  const endIndex = primaryHeadline.indexOf(']')
  const arrayString = primaryHeadline.substring(startIndex, endIndex)
  const words = arrayString
    .split(/(<\/span>),\s/)
    .map(item => item.trim())
    .map(item => item.replace('</span>', ''))
    .filter(item => item !== '')

  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length)
    }, 1500)

    return () => clearInterval(interval)
  }, [words])

  const renderedTitle = `${
    stringWithoutBracketContents.slice(0, startIndex - 1) +
    `<br />` +
    words[currentWordIndex] +
    stringWithoutBracketContents.slice(startIndex - 1)
  }`

  return (
    <div
      className={clsx(
        'container | font-heading | md:text-center | py-10 md:py-18'
      )}
    >
      {primaryHeadline.includes('[') && primaryHeadline.includes(']') ? (
        <h1
          className={clsx(
            'title animate max-w-col-12 mx-auto | text-4xl lg:text-6xl font-medium | mb-4',
            styles.title
          )}
          dangerouslySetInnerHTML={{ __html: renderedTitle }}
        />
      ) : (
        <h1
          className={clsx(
            'title max-w-col-12 mx-auto | text-4xl lg:text-6xl font-medium | mb-4',
            styles.title
          )}
          dangerouslySetInnerHTML={{ __html: primaryHeadline }}
        />
      )}
      {subText ? (
        <div
          className="font-body font-normal text-lg lg:text-xl | max-w-col-8 mx-auto mb-10"
          dangerouslySetInnerHTML={{ __html: subText }}
        />
      ) : null}
      <div className="font-body font-medium | flex flex-col md:flex-row justify-center gap-4 | mb-6">
        <Link href={primaryCta.href} className="new-btn-primary | font-medium">
          {primaryCta.title}
        </Link>
        {secondaryCta && (
          <Link
            href={secondaryCta.href}
            className={clsx(
              mode === 'dark' ? 'new-btn-secondary' : 'new-btn-blue-outline',
              'font-bold'
            )}
          >
            {secondaryCta.title}
          </Link>
        )}
      </div>
      {footNote ? (
        <div
          className="font-body font-medium text-sm | max-w-col-8 | mx-auto"
          dangerouslySetInnerHTML={{ __html: footNote }}
        />
      ) : null}

      {videoLink || image ? (
        image ? (
          <Image
            src={image.url}
            alt={image.alternativeText ?? ''}
            width={image.width}
            height={image.height}
            className="rounded-2xl | max-w-5xl w-full | mt-10 mx-auto"
            priority
          />
        ) : (
          <YoutubePlayer
            className="block | max-w-5xl w-full | aspect-video | mt-10 mx-auto p-0"
            videoId={videoLink?.split('/embed/')[1]}
            posterLoading="eager"
          />
        )
      ) : null}
    </div>
  )
}

function LeftLayout({
  primaryHeadline,
  subText,
  footNote,
  primaryCta,
  secondaryCta,
  image,
  mode
}: Props): JSX.Element {
  return (
    <div className="container | font-heading | grid grid-cols-4 lg:grid-cols-12 | gap-x-8 gap-y-12 items-center | text-left | py-10 md:py-18">
      <div className="col-span-4 lg:col-span-5 | heroTitle">
        {primaryHeadline ? (
          <h1
            className="title | text-4xl lg:text-6xl font-medium tracking-tight | mb-4"
            dangerouslySetInnerHTML={{ __html: primaryHeadline }}
          />
        ) : null}
        {subText ? (
          <h2
            className={clsx(
              'font-body text-lg lg:text-xl | mb-10 lg:mb-12',
              styles.description
            )}
            dangerouslySetInnerHTML={{ __html: subText }}
          />
        ) : null}
        <div className="font-body font-medium | flex flex-col sm:flex-row justify-center md:justify-start | gap-4 mb-6">
          <Link
            href={primaryCta.href}
            className="new-btn-primary | font-medium"
          >
            {primaryCta.title}
            <ArrowLeft className="ml-3" />
          </Link>
          {secondaryCta && (
            <Link
              href={secondaryCta.href}
              className={clsx(
                mode === 'dark' ? 'new-btn-secondary' : 'new-btn-blue-outline',
                'font-medium'
              )}
            >
              {secondaryCta.title}
              <ArrowLeft className="ml-3" />
            </Link>
          )}
        </div>
        {footNote ? (
          <div
            className="font-body font-medium text-sm"
            dangerouslySetInnerHTML={{ __html: footNote }}
          />
        ) : null}
      </div>
      <div className="col-span-4 lg:col-start-7 lg:col-span-6">
        {image && (
          <Image
            src={image.url}
            alt={image.alternativeText ?? ''}
            width={image.width}
            height={image.height}
            className="rounded-2xl | w-full max-w-col-6 | mx-auto"
            priority
          />
        )}
      </div>
    </div>
  )
}

export default PageHero
