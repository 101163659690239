import Link from '@/atoms/Link/Link'
import Image from 'next/image'

/* assets */
import { ReactComponent as AIIcon } from 'public/assets/homePage/icons/ai-icon.svg'
import { ReactComponent as EditIcon } from 'public/assets/homePage/icons/edit-icon.svg'
import { ReactComponent as ListIcon } from 'public/assets/homePage/icons/list-icon.svg'
import { ReactComponent as ArrowRight } from 'public/assets/icons/chevron-right.svg'

const GenAIForTeams = () => {
  return (
    <section
      style={{
        background: 'linear-gradient(91.79deg, #6F16EB 1.55%, #62E1FD 118.7%)'
      }}
    >
      <div className="container | text-white | flex flex-col gap-12 | py-10 md:py-18">
        <div className="top | flex flex-wrap gap-16 md:gap-24 md:items-center | md:grid grid-cols-2">
          <h2 className="text-3xl md:text-6xl font-medium font-heading | max-w-col-6">
            Generative AI for your teams
          </h2>
          <ul className="text-xl font-body font-semibold | flex flex-col gap-6">
            <li className="flex items-center gap-4">
              <span className="h-6 w-6">
                <ListIcon />
              </span>
              <p className="leading-2">
                AI-powered content optimisation suggestions
              </p>
            </li>
            <li className="flex items-center gap-4">
              <span className="h-6 w-6">
                <ListIcon />
              </span>
              <p className="leading-2">Curated SEO strategies for your brand</p>
            </li>
            <li className="flex items-center gap-4">
              <span className="h-6 w-6">
                <ListIcon />
              </span>
              <p className="leading-2">
                Data-driven content planning recommendations
              </p>
            </li>
          </ul>
        </div>
        <div className="bottom | flex flex-col gap-16 md:gap-24 | md:grid grid-cols-2">
          <div className="section | flex flex-col gap-9">
            <div className="caption | flex gap-3 items-center">
              <AIIcon />
              <h4 className="font-semibold text-paragraph-1 font-heading">
                PEPPER AI
              </h4>
            </div>
            <div className="image | relative">
              <Image
                src={'/assets/homePage/images/pepper-ai.png'}
                alt="Screenshot of Pepper AI product"
                height="1035"
                width="1158"
              />
            </div>
            <p className="subtitle | font-heading font-medium text-3xl md:text-4xl">
              {`Your content team's most powerful ally`}
            </p>
            <p className="description | font-body text-xl">
              The ease of ChatGPT combined with the power of enhanced workflows
              and curated marketing prompts
            </p>
            <div className="cta | border border-white px-4 py-2 rounded-lg | w-fit | font-body font-medium">
              <Link
                href="/pepper-ai/"
                className="inline-flex items-center justify-center"
              >
                Learn More
                <ArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
          <div className="section | flex flex-col gap-9">
            <div className="caption | flex gap-3 items-center">
              <EditIcon />
              <h4 className="font-semibold text-paragraph-1 font-heading">
                PEPPERTYPE AI
              </h4>
            </div>
            <div className="image | relative">
              <Image
                src={'/assets/homePage/images/peppertype.png'}
                alt="Screenshot of PeppertypeAI product"
                height="1035"
                width="1158"
              />
            </div>
            <p className="subtitle | font-heading font-medium text-3xl md:text-4xl">
              Your personalized AI writing assistant
            </p>
            <p className="description | font-body text-xl">
              Leverage the power of AI to ideate and create optimised,
              plagiarism-free content at speed and scale
            </p>
            <div className="cta | border border-white px-4 py-2 rounded-lg | w-fit | font-body font-medium">
              <Link
                href="/peppertype-ai/"
                className="inline-flex items-center justify-center"
              >
                Learn More
                <ArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GenAIForTeams
