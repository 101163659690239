import clsx from 'clsx'
import Image from 'next/image'
import useWindowSize from '@/hooks/useWindowSize'

/* blocks */
import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as ArrowRight } from 'public/assets/icons/chevron-right.svg'
import HeroImage from 'public/assets/global-marketing-leaders/influencer_2023_hero.png'
import DeskLeader from 'public/assets/global-marketing-leaders/leaders-desk.png'
import MobLeader from 'public/assets/global-marketing-leaders/leaders-mob.png'
import { ReactComponent as DeskTextBg } from 'public/assets/global-marketing-leaders/text-bg-desk.svg'
import { ReactComponent as MobTextBg } from 'public/assets/global-marketing-leaders/text-bg-mob.svg'

interface Props {
  screenSize: number
}

function CMLPromotionalFooter(): JSX.Element {
  const { size } = useWindowSize()
  return (
    <section
      className={clsx(
        'container | font-body text-white text-center | py-5 md:py-9'
      )}
      style={{
        background: 'public/assets/global-marketing-leaders/text-bg-desk.svg'
      }}
    >
      {size.width < 1024 ? (
        <PromotionalFooterMobile screenSize={size.width} />
      ) : (
        <PromotionalFooterDesktop />
      )}
    </section>
  )
}

function PromotionalFooterDesktop(): JSX.Element {
  return (
    <div
      className="grid grid-cols-12 gap-x-6 lg:gap-x-8 items-center  | rounded-2xl overflow-hidden"
      style={{
        background: 'linear-gradient(180deg, #1600E5 0.76%, #5C11C4 86.59%)'
      }}
    >
      <div className="col-span-5 relative | z-20">
        <Image
          src={HeroImage.src}
          alt=""
          width={HeroImage.width}
          height={HeroImage.height}
          className="w-40 py-5 px-4"
        />
        <div className="w-[662px]">
          <Image
            src={DeskLeader.src}
            alt=""
            width={DeskLeader.width}
            height={DeskLeader.height}
          />
        </div>
      </div>
      <div className="col-span-1"></div>
      <div className="col-span-6 | flex flex-col justify-center relative">
        <div className="absolute right-0 z-0">
          <DeskTextBg />
        </div>
        <div className=" max-w-col-5 mx-auto z-10">
          <h2 className="text-2xl | mb-8">
            Are you ready to learn about the state and future of content
            marketing?
          </h2>
          <div className="font-medium flex flex-col sm:flex-row justify-center | gap-4">
            <Link
              href="/global-marketing-leaders/"
              className="inline-flex items-center justify-center | bg-white text-gray-90 text-xl rounded-md | max-h-14 py-4 px-6"
            >
              Get Insights
              <ArrowRight className="ml-2 | text-purple-60" />
            </Link>
            <Link
              href="/resources/ebook/the-future-of-content-marketing-ebook/"
              className="new-btn-secondary"
            >
              Download Ebook
              <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function PromotionalFooterMobile({ screenSize }: Props): JSX.Element {
  return (
    <div
      className="grid grid-cols-1 gap-x-6 lg:gap-x-8 items-center  | rounded-2xl overflow-hidden"
      style={{
        background: 'linear-gradient(180deg, #1600E5 0.76%, #5C11C4 86.59%)'
      }}
    >
      <div className="flex flex-col justify-center relative | py-12 px-5">
        {screenSize <= 360 ? (
          <div className="absolute right-0 z-0">
            <MobTextBg />
          </div>
        ) : null}
        <div className="max-w-col-5 mx-auto z-10">
          <h2 className="text-2xl | mb-8">
            Are you ready to learn about the state and future of content
            marketing?
          </h2>
          <div className="font-body font-medium flex flex-col sm:flex-row justify-center | gap-4">
            <Link
              href={'/global-marketing-leaders/leader/'}
              className="inline-flex items-center justify-center | bg-white text-gray-90 text-xl rounded-md | max-h-14 py-4 px-6"
            >
              Get Insights
              <ArrowRight className="ml-2 | text-purple-60" />
            </Link>
            <Link
              href={'/resources/ebook/the-future-of-content-marketing/'}
              className="new-btn-secondary"
            >
              Download Ebook
              <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </div>
      <div className="relative | mx-auto z-20 w-[312px]">
        <Image
          src={MobLeader.src}
          alt=""
          width={MobLeader.width}
          height={MobLeader.height}
        />
      </div>
    </div>
  )
}

export default CMLPromotionalFooter
