import { useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

/* assets */
import { ReactComponent as PeopleIcon } from 'public/assets/homePage/icons/people.svg'
import { ReactComponent as MapIcon } from 'public/assets/icons/fi:map-pin.svg'

/* styling */
import styles from './_.module.css'

function ExpertNetwork(): JSX.Element {
  const [activeCreator, setActiveCreator] = useState<(typeof creators)[0]>(
    creators[0]
  )

  return (
    <section className="bg-blue-web-90 | py-10 md:py-18">
      <div className="container">
        <div className="text-yellow-50 | flex items-center gap-2 | mb-4">
          <PeopleIcon />
          <p className="text-sm lg:text-xl font-heading font-semibold tracking-widest uppercase">
            Expert Network
          </p>
        </div>
        <h2 className="text-3xl lg:text-6xl text-white font-heading font-medium | max-w-col-8 | mb-24 lg:mb-14">
          Expert-led content creation
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-12 items-center gap-14 lg:gap-8 | mb-10">
          <h3 className="lg:col-span-5 | text-white text-2xl lg:text-3xl font-medium font-heading">
            Pepper has helped brands create high-quality content for brands with
            our exclusive top 3% subject matter experts from your industry
          </h3>

          <div className="lg:col-span-7 | relative">
            <div className="lg:absolute right-0 top-5 | mb-12">
              <CreatorDetailsCard className="mx-auto" creator={activeCreator} />
            </div>

            <Image
              src={activeCreator.image}
              alt={activeCreator.name}
              width="448"
              height="448"
              className="max-w-full w-96 h-96 | object-cover | mx-auto lg:mx-0"
            />
          </div>
        </div>

        <div
          className={clsx(
            'flex gap-6 | snap-x snap-mandatory overflow-auto | pb-5',
            styles.scrollbar
          )}
        >
          {creators.map((creator, index) => (
            <CreatorCard
              key={index}
              className="shrink-0 | snap-center"
              isActive={creator.name === activeCreator.name}
              creator={creator}
              onClick={() => setActiveCreator(creator)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

function CreatorCard({
  isActive,
  className,
  creator,
  onClick
}: {
  isActive: boolean
  className: string
  creator: (typeof creators)[0]
  onClick: () => void
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'bg-white | border-4 rounded | max-w-[12rem]',
        isActive ? 'border-yellow-50' : 'border-transparent',
        className
      )}
    >
      <Image
        src={creator.image}
        alt=""
        width="448"
        height="448"
        className="rounded-t | object-cover w-48 h-48"
      />
      <div className="text-left | p-3">
        <p className="text-base lg:text-xl text-blue-web-90 font-medium | mb-0.5">
          {creator.name}
        </p>
        <p className="text-xs text-blue-web-90">{creator.profession}</p>
      </div>
    </button>
  )
}

function CreatorDetailsCard({
  className,
  creator
}: {
  className: string
  creator: (typeof creators)[0]
}) {
  return (
    <div
      className={clsx(
        'bg-white text-blue-90 | relative | rounded | max-w-col-3 | py-5 px-6',
        className
      )}
    >
      <div className="flex items-center justify-between | mb-1.5">
        <p className="text-xl font-medium">{creator.name}</p>
        <p className="bg-green-20 text-green-80 text-caption | flex items-center gap-1.5 | rounded-sm | px-2 py-0.5">
          <MapIcon className="" /> {creator.location}
        </p>
      </div>

      <div className="flex items-center flex-wrap gap-2 | mb-8">
        <p>{creator.profession}</p>
        <div className="bg-yellow-30 | rounded-full | w-1 h-1" />
        <p>{creator.info}</p>
      </div>

      <div className="flex gap-2 flex-wrap">
        {creator.tags.map(tag => (
          <p
            key={tag}
            className="bg-yellow-30 text-xs | border-1 border-gray-20 rounded-full | w-fit | px-2 py-1"
          >
            {tag}
          </p>
        ))}
      </div>

      <div className="absolute -top-3 -left-3 | bg-purple-50 | border-4 border-white rounded-full | w-7 h-7" />
    </div>
  )
}

const creators = [
  {
    image: '/assets/homePage/images/speakers/clair.png',
    name: 'Clair Bel',
    location: 'Seattle,US',
    profession: 'Content Writer',
    info: '10 years experience',
    tags: ['Thought Leadership ', 'AI', 'Cloud Security', 'SEO']
  },
  {
    image: '/assets/homePage/images/speakers/bari_siegel.png',
    name: 'Bari Siegel',
    location: 'Canada',
    profession: 'Editor',
    info: '17 years experience',
    tags: ['SEO Blog/Article', 'Healthcare ', 'Website Content', 'Wellness']
  },
  {
    image: '/assets/homePage/images/speakers/namita.png',
    name: 'Namita S.',
    location: 'Delhi,India',
    profession: 'Blog writer',
    info: '10 years experience',
    tags: ['SEO Blog/Article', 'IT, Software', 'SEO']
  },
  {
    image: '/assets/homePage/images/speakers/smita.png',
    name: 'Smita Singh',
    location: 'Agra,India',
    profession: 'Healthcare specialist',
    info: '6 years experience',
    tags: ['Travel & Hospitality', 'Copywriting', 'SEO Blogs']
  },
  {
    image: '/assets/homePage/images/speakers/amrit_kaur.png',
    name: 'Amrit Kaur',
    location: 'Indore,India',
    profession: 'Fintech expert',
    info: '12 years experience',
    tags: ['SEO Blog/Article', 'B2B-Saas', 'Website Content', 'SEO']
  },
  {
    image: '/assets/homePage/images/speakers/aurora.png',
    name: 'Aurora S',
    location: 'Boston,US',
    profession: 'Editor',
    info: '12 years experience',
    tags: ['SME - Copywriting', 'Fitness', 'SME - SEO Blog', 'Healthcare']
  },
  {
    image: '/assets/homePage/images/speakers/carolyn.png',
    name: 'Carolyn ',
    location: 'York,UK',
    profession: 'Product marketer',
    info: '13 years experience',
    tags: ['Thought Leadership', 'Legal', 'Customer Education ']
  },
  {
    image: '/assets/homePage/images/speakers/benjamin.png',
    name: 'Benjamin',
    location: 'Chicago,US',
    profession: 'Content marketer',
    info: '8 years experience',
    tags: ['In-Depth Content', 'SEO Blogs', 'B2B Marketing']
  },
  {
    image: '/assets/homePage/images/speakers/daniel_doan.png',
    name: 'Daniel Doan',
    location: 'Boston,US',
    profession: 'SaaS content writer',
    info: '11 years experience',
    tags: ['Marketing and Advertising', 'IT, Software', 'Copywriting']
  },
  {
    image: '/assets/homePage/images/speakers/akanksha.png',
    name: 'Akanksha',
    location: 'Pune,India',
    profession: 'Journalist',
    info: '9 years experience',
    tags: ['SEO Blog/Article', 'In-Depth Blog', 'Lifestyle']
  }
]

export default ExpertNetwork
