/* blocks */
import Awards from '@/cmp/organism/Awards/Awards'
import CustomerLogo from '@/cmp/organism/CustomerLogo/CustomerLogo'
import PageHero from '@/cmp/organism/PageHero/PageHero'
import TestimonialCarousel from '@/cmp/organism/TestimonialCarousel/TestimonialCarousel'
import Quotes from './components/Quotes/Quotes'
import PlatformFeatures from './components/PlatformFeatures/PlatformFeatures'
import ExpertNetwork from './components/ExpertNetwork/ExpertNetwork'
import CaseStudyGallery from './components/CaseStudyGallery/CaseStudyGallery'
import GenAIForTeams from './components/GenAIForTeams/GenAIForTeams'
import HomePepperAdvantage from './components/PepperAdvantage/PepperAdvantage'
import CMLPromotionalFooter from '@/cmp/organism/CMLPromotionalFooter/CMLPromotionalFooter'
import FooterExtended from './components/FooterExtended/FooterExtended'

/* utils */
import { NewHomePageAttributes } from '@/utils/queries/new-homepage'

interface Props {
  data: NewHomePageAttributes
}

function HomePageTemplate({ data }: Props): JSX.Element {
  return (
    <>
      <PageHero
        primaryHeadline={data.hero.title}
        subText={data.hero.description}
        primaryCta={data.hero.primaryCta}
        image={data.hero.image?.data?.attributes}
        videoLink={data.hero.videoLink}
      />

      <Awards
        title={homepageData.awards.title}
        images={homepageData.awards.imageList}
      />

      {data.brandList.brands.data.length > 0 ? (
        <CustomerLogo
          title={data.brandList.title}
          brands={data.brandList.brands.data.map(i => ({
            ...i.attributes.logo.data.attributes,
            alternativeText: i.attributes.name
          }))}
        />
      ) : null}

      {data.testimonialList.data.length > 0 ? (
        <TestimonialCarousel
          testimonials={data.testimonialList.data.map(i => {
            return {
              authorName: i.attributes.authorName,
              authorDesignation: i.attributes.authorDesignation,
              authorImage: i.attributes?.authorImage,
              company: i.attributes.company,
              companyImage: i.attributes?.companyImage,
              review: i.attributes.review
            }
          })}
        />
      ) : null}
      <Quotes />
      <PlatformFeatures />
      <ExpertNetwork />
      <GenAIForTeams />
      <HomePepperAdvantage />
      {data.caseStudyList.length > 0 ? (
        <CaseStudyGallery
          title={data.caseStudyTitle}
          lists={data.caseStudyList}
        />
      ) : null}
      <CMLPromotionalFooter />
      <FooterExtended />
    </>
  )
}

const homepageData = {
  awards: {
    title: `A trusted partner in <br/> 2500+ growth stories`,
    imageList: [
      {
        id: 1,
        image: {
          url: '/assets/homePage/images/awards/easiestToUse.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        id: 2,
        image: {
          url: '/assets/homePage/images/awards/highPerformer.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        id: 3,
        image: {
          url: '/assets/homePage/images/awards/leaderWinter.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        id: 4,
        image: {
          url: '/assets/homePage/images/awards/leaderSmallBusiness.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        id: 5,
        image: {
          url: '/assets/homePage/images/awards/top100.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        id: 6,
        image: {
          url: '/assets/homePage/images/awards/momentumLeader.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      }
    ]
  }
}

export default HomePageTemplate
