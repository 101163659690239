import { Fragment, useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import useEmblaCarousel from 'embla-carousel-react'

/* styling */
import styles from './_.module.css'

interface Props {
  testimonials: StrapiTestimonialAttributes[]
}

const AUTO_SCROLL_TIMER = 5000 // in ms

function TestimonialsCarousel({ testimonials }: Props): JSX.Element {
  const [carouselRef, carousel] = useEmblaCarousel({
    draggable: false,
    loop: true
  })
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0)
  const [carouselInterrupt, setCarouselInterrupt] = useState(false)

  // on swipe & auto scroll
  const handleCarouselNext = useCallback(() => {
    if (!carousel) return
    carousel.scrollNext()
    setActiveCarouselIndex(prevIndex => (prevIndex + 1) % testimonials.length)
  }, [carousel, testimonials.length])

  useEffect(() => {
    let interval: NodeJS.Timeout

    const callback = (entries: IntersectionObserverEntry[]) => {
      // reset the auto-scroll timer when user changes the slider
      if (carouselInterrupt) {
        clearInterval(interval)
        setCarouselInterrupt(false)
      }

      // start auto-scroll only when the carousel is in the user's viewport
      if (entries[0].isIntersecting) {
        interval = setInterval(() => handleCarouselNext(), AUTO_SCROLL_TIMER)
      } else {
        clearInterval(interval)
      }
    }

    const observer = new IntersectionObserver(callback, { threshold: 0.5 })
    const target = document.querySelector('#js-testimonial-carousel')
    if (target) {
      observer.observe(target)
    }

    return () => {
      clearInterval(interval)
      observer.disconnect()
    }
  }, [carousel, carouselInterrupt, handleCarouselNext])

  // on clicking author's image
  const handleCarouselScrollTo = (index: number) => {
    if (!carousel) return

    carousel.scrollTo(index)
    setActiveCarouselIndex(index)
    setCarouselInterrupt(true)
  }

  return (
    <Fragment>
      <div className="bg-blue-web-90 text-white py-12">
        <div className="flex items-center justify-center mb-8">
          {testimonials.map((testimonial, index) => (
            <button
              key={index}
              className="relative  mr-3  h-12 w-12"
              onClick={() => handleCarouselScrollTo(index)}
            >
              {activeCarouselIndex === index && (
                <Fragment>
                  {/* prettier-ignore */}
                  <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" className="absolute  left-0 right-0 top-0">
                  <g>
                    <title>Image Timer</title>
                    <circle className={styles.svgImageCircle} r="22" cy="24" cx="24" strokeWidth="4" stroke={"#FFCA33"} fill="none"/>
                  </g>
                </svg>
                </Fragment>
              )}
              <div className="absolute left-1 top-1 w-10">
                <Image
                  alt={testimonial.authorName}
                  src={testimonial.authorImage?.data?.attributes.url ?? ''}
                  width={40}
                  height={40}
                  className="w-10 h-10 object-cover | rounded-full"
                />
              </div>
            </button>
          ))}
        </div>

        <div
          className="max-w-screen-md  mx-auto  overflow-hidden"
          ref={carouselRef}
          id="js-testimonial-carousel"
        >
          <div className="flex  text-center">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="relative  min-w-full px-6 md:px-0">
                <p className="text-paragraph-1  |  mb-6">
                  {testimonial.review.replace(/```/g, '')}
                </p>
                <strong className="text-paragraph-4 font-normal  |  block  |  mb-4">
                  <span className="font-medium">
                    {testimonial.authorName},&nbsp;
                  </span>
                  {testimonial.authorDesignation}{' '}
                  {testimonial.company ? `, ${testimonial.company}` : ''}
                </strong>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TestimonialsCarousel
