import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as DownIcon } from 'public/assets/homePage/images/Arrow.svg'

function Quotes() {
  return (
    <section className="container font-heading | grid grid-cols-1 md:grid-cols-12 gap-8 | py-10 md:py-18">
      <div className="md:col-span-2 | border-b-4 md:border-r-4 md:border-b-0 border-blue-web-50">
        <Image
          src="/assets/homePage/images/quote.png"
          alt="Quotes"
          width={102}
          height={71}
          className="mb-8"
        />
      </div>
      <div className="md:col-start-3 md:col-span-9 |border-t-4 border-blue-web-50 border-spacing-9">
        <div className="text-3xl md:text-[55px] leading-2 font-medium | mb-14 lg:max-w-col-10 lg:pr-22">
          Pepper Content enables CMOs at enterprises and fast-growing companies
          to grow ROI and revenue by helping them create high-quality content
          and track its lifecycle in{' '}
          <span className="text-blue-web-50">one collaborative platform.</span>
        </div>
        <Link
          href={`#platform-features`}
          className="flex items-center gap-2 | mt-auto"
        >
          <p className="text-3xl md:text-[55px] text-gray-90 font-medium">
            Here&apos;s how
          </p>
          <DownIcon className="ml-6" />
        </Link>
      </div>
    </section>
  )
}

export default Quotes
